<template>
    <div class="menu fixed flex">
        <div
            class="flex relative justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
            text-white shadow-md transition hover:bg-green-500 cursor-pointer"
            @click="showDateRangePicker"
        >
            <p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11H16C16.5523 11 17 10.5523 17 10C17 9.44771 16.5523 9 16 9H8Z"
                        fill="currentColor"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21
                        4.34315 19.6569 3 18 3H6ZM5 18V7H19V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18Z"
                        fill="currentColor"
                    />
                </svg>
            </p>
            <div class="flex absolute w-4 justify-center ">
                <el-date-picker
                    ref="dateRangePicker"
                    v-model="dateRange"
                    :clearable="false"
                    type="daterange"
                    :picker-options="{
                        firstDayOfWeek: 1
                    }"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    @change="dateRangeChange"
                />
            </div>
        </div>

        <SettingsShowHideButton
            :isWeekShowHideVisible="isWeekShowHideVisible"
            @changeCalendarSettings="$emit('changeCalendarSettings', $event)"
            @visibilityChange="settingsShowHideVisibility"
        />

        <!-- Show/hide week days -->
        <WeekShowHideButton
            :isSettingsShowHideVisible="isSettingsShowHideVisible"
            @change="$emit('changeDaysVisibility', $event)"
            @visibilityChange="weekShowHideVisibility"
        />

        <!-- Prev time period -->
        <div
            class="flex justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
            text-white shadow-md transition hover:bg-green-500 cursor-pointer"
            @click="previousWeek"
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
                    fill="currentColor"
                />
            </svg>
        </div>

        <!-- Next time period -->
        <div
            class="flex justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
            text-white shadow-md transition hover:bg-green-500 cursor-pointer"
            @click="nextWeek"
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z" fill="currentColor" />
            </svg>
        </div>

        <!-- goToToday -->
        <p
            class="flex justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
            text-white shadow-md transition hover:bg-green-500 cursor-pointer"
            @click="goToToday"
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21 8.77217L14.0208 1.79299C12.8492 0.621414 10.9497 0.621413 9.77817 1.79299L3 8.57116V23.0858H10V17.0858C10
                    15.9812 10.8954 15.0858 12 15.0858C13.1046 15.0858 14 15.9812 14 17.0858V23.0858H21V8.77217ZM11.1924 3.2072L5
                    9.39959V21.0858H8V17.0858C8 14.8767 9.79086 13.0858 12 13.0858C14.2091 13.0858 16 14.8767 16 17.0858V21.0858H19V9.6006L12.6066
                    3.2072C12.2161 2.81668 11.5829 2.81668 11.1924 3.2072Z"
                    fill="currentColor"
                />
            </svg>
        </p>
    </div>
</template>
<script>
export default {
    components: {
        WeekShowHideButton:     () => import(/* webpackChunkName: "TimeLineFloatingMenu/WeekShowHideButton" */ './components/WeekShowHideButton'),
        SettingsShowHideButton:
            () => import(/* webpackChunkName: "TimeLineFloatingMenu/SettingsShowHideButton" */ './components/SettingsShowHideButton'),
    },

    data() {
        return {
            days:                      7,
            datePickerVisible:         false,
            visible:                   false,
            isWeekShowHideVisible:     false,
            isSettingsShowHideVisible: false,
            dateRange:                 [
                this.$dayjs(),
                this.$dayjs().add(7, 'day'),
            ],
        };
    },

    methods: {
        showOptions() {
            this.visible = true;
        },

        hideOptions() {
            this.visible = false;
        },

        changeDaysNumber(days) {
            this.days = days;
            this.$emit('daysChange', days);
            this.hideOptions();
        },

        showDateRangePicker() {
            if (this.datePickerVisible) {
                this.datePickerVisible = false;
                return;
            }
            this.datePickerVisible = true;
            this.$refs.dateRangePicker.focus();
        },

        previousWeek() {
            this.$emit('previousTimePeriod');
        },

        nextWeek() {
            this.$emit('nextTimePeriod');
        },

        goToToday() {
            this.$emit('goToToday');
        },

        dateRangeChange() {
            const from = this.$dayjs(this.dateRange[0]);
            const to = this.$dayjs(this.dateRange[1]);
            const daysToShow = to.diff(from, 'day') + 1;

            this.$emit('dateRangeChanged', {
                from,
                to,
                daysToShow,
            });
        },

        settingsShowHideVisibility(event) {
            this.isSettingsShowHideVisible = event;
            if (event) {
                this.isWeekShowHideVisible = false;
            }
        },

        weekShowHideVisibility(event) {
            this.isWeekShowHideVisible = event;
            if (event) {
                this.isSettingsShowHideVisible = false;
            }
        },
    },
};
</script>
<style scoped>
    .menu {
        bottom: 10px;
        right: 10px;
        z-index: 200;
    }

    .el-range-editor {
        visibility: hidden;
    }
</style>
